import { Button, Input, InputAdornment, TableRow } from "@mui/material";
import styles from "./ProductTile.module.css";
import { useState } from "react";


const ProductTile = ({ item, onAddToBasketClick }) => {
  const name = item[0];
  const price = item[1];
  const unit = item[2];
  const amountPerUnit = item[3];
  const imgLink = item[4];
  const available = (item[5].toLowerCase?.() === "true")

  const [amount, setAmount] = useState(0.5);
  const onAmountDecrease = () => {
    if (amount > 0.5) setAmount(amount - 0.5);
  };
  const onAmountIncrease = () => {
    setAmount(amount + 0.5);
  };

  const resetAmount = () => {
    setAmount(0.5);
  };

  return (
    <div className={styles.tile}>
      {!available && <div className={styles.overlay}></div>}
      <div className={styles.imgDiv}>
        <img src={imgLink} alt={name}></img>
      </div>

      <div className={styles.infoPart}>
        <div className={styles.productName}>
          <h3>{name}</h3>
        </div>
        <div className={styles.price}>
          {available && <h3>
            Cena: {price} zł/{unit}
          </h3>}
          {!available && <h3>
            Niedostępne
          </h3>}
          
        </div>
        <h3>Ilość {amountPerUnit}</h3>
      </div>
      <div className={styles.amountRow}>
        <Button
          onClick={onAmountDecrease}
          variant="contained"
          style={{ backgroundColor: "#2f82f7" }}
          disabled={!available}
        >
          -
        </Button>
        <Input
          id="standard-adornment-weight"
          type="text"
          step={0.5}
          value={amount}
          disabled={!available}
          endAdornment={<InputAdornment position="end">kg</InputAdornment>}
          aria-describedby="standard-weight-helper-text"
          inputProps={{
            "aria-label": "Ilość",
          }}
          className={styles.inputBox}
          size="small"
        />
        <Button
          onClick={onAmountIncrease}
          variant="contained"
          style={{ backgroundColor: "#2f82f7" }}
          disabled={!available}
        >
          +
        </Button>
      </div>
      <div className={styles.addButton}>
        <Button
          style={{
            backgroundColor: "#2f82f7",
            fontFamily: "Playfair Display, serif",
          }}
          variant="contained"
          onClick={() => {
            onAddToBasketClick(amount);
            resetAmount();
          }}
          disabled={!available}
        >
          Dodaj do koszyka
        </Button>
      </div>
    </div>
  );
};

export default ProductTile;
