import styles from "./Products.module.css";
import { useState, useEffect, useContext, useRef } from "react";
import ProductTile from "./ProductTile";

import { BasketContext } from "../../BasketContext";
import SwitchSelector from "react-switch-selector";
import { Button, Input } from "@mui/material";

import { BsSearch } from "react-icons/bs";
import apiRouting from "../../apiRouting";

function compareProduct(a, b){
  const available1 = (a[5].toLowerCase?.() === "true")
  const available2 = (b[5].toLowerCase?.() === "true")
  
  return Number(available2) - Number(available1)
}

const Products = (props) => {
  const [products, setProducts] = useState(props.products);
  const { addProduct } = useContext(BasketContext);

  const [selectedList, setSelectedList] = useState("normal");

  const filter = useRef("");

  const onChangeList = (newValue) => {
    setSelectedList(newValue);
  };

  const options = [
    {
      label: "Produkty",
      value: "normal",
      selectedBackgroundColor: "#0097e6",
    },
    {
      label: "Produkty bezglutenowe",
      value: "gluten",
      selectedBackgroundColor: "#fbc531",
    },
  ];

  useEffect(() => {
    if (typeof products === "undefined")
      fetch(apiRouting.apiAddess)
        .then((response) => response.json())
        .then((data) => {
          var normal = data.list.normal;
          var glutenFree = data.list.glutenFree;
          
          
          normal.shift();

          glutenFree.shift();

          normal = normal.sort((a,b) => compareProduct(a,b))
          glutenFree = glutenFree.sort((a,b) => compareProduct(a,b))

          const parsedLists = {
            normal,
            glutenFree,
            filteredNormal: normal,
            filteredGlutenFree: glutenFree,
          };

          setProducts(parsedLists);
        });
  }, []);

  if (typeof products === "undefined") {
    return <div></div>;
  }

  var initialIndex = selectedList === "gluten" ? 1 : 0;

  let generateList = products.filteredNormal.map((item, i) => (
    <ProductTile
      key={i}
      item={item}
      onAddToBasketClick={(amount) => addProduct(item, amount)}
    />
  ));
  if (selectedList === "gluten") {
    generateList = products.filteredGlutenFree.map((item, i) => (
      <ProductTile
        key={i}
        item={item}
        onAddToBasketClick={(amount) => addProduct(item, amount)}
      />
    ));
  }

  const filterList = () => {
    const filteredNormal = products.normal.filter((p) =>
      p[0].toLowerCase().includes(filter.current)
    );

    const filteredGlutenFree = products.glutenFree.filter((p) =>
      p[0].toLowerCase().includes(filter.current)
    );

    setProducts({
      normal: products.normal,
      glutenFree: products.glutenFree,
      filteredNormal: filteredNormal,
      filteredGlutenFree: filteredGlutenFree,
    });
  };

  const onFilterChange = (e) => {
    filter.current = e.target.value.toLowerCase();

    if (filter.current === "") {
      setTimeout(
        () =>
          setProducts({
            normal: products.normal,
            glutenFree: products.glutenFree,
            filteredNormal: products.normal,
            filteredGlutenFree: products.glutenFree,
          }),
        5
      );
    }
  };

  //generateList = generateList.filter(p => p.name.toLowerCase().includes(filterValue));

  return (
    <>
    {/*
    
    <div className={styles.noProducts}>
      <h2>Informujemy, że produkty w tym roku zostały już wyprzedane.</h2>
      <h2>Zapraszamy do składania zamówień w styczniu.</h2>
    </div>
    */
  }
      <div className={styles.options}>
       
        <SwitchSelector
          onChange={onChangeList}
          options={options}
          optionBorderRadius={0}
          selectionIndicatorMargin={0}
          wrapperBorderRadius={8}
          initialSelectedIndex={initialIndex}
        />
        
      </div>
      <div className={styles.search}>
      <h2>
          Płatność gotówką przy odbiorze, lub przelewem po otrzymaniu
          zamówienia na podstawie paragonu.
        </h2>
        <h2>Szukaj produktów</h2>
        <Input onChange={onFilterChange}></Input>
        <Button style={{ marginLeft: "1rem" }} onClick={filterList}>
          <BsSearch size={32}></BsSearch>
        </Button>
      </div>
      <div className={styles.productsBox}>{generateList}</div>
    </>
  );
};

export default Products;
