import Logo from "../Logo/Logo";

import styles from "./TopBar.module.css";
import RoutesBar from "./RoutesBar";

import { useState } from "react";
import BasketIcon from "../../Components/Basket/BasketIcon";

import MenuBurger from "../MenuBurger/MenuBurger";
import Basket from "../../Components/Basket/Basket";

const isBurgerVisible = () => {
  //console.log(window.innerWidth);
  if (window.innerWidth > 1360) {
    return false;
  }

  return true;
};

const Topbar = (props) => {
  const [menuActive, setMenuActive] = useState(false);

  const [cartActive, setCartActive] = useState(false);

  const handleCartClick = () => {
    //console.log(cartActive);
    setCartActive(!cartActive);
  };

  const clickMenuHandler = () => {
    if (isBurgerVisible()) {
      setMenuActive(!menuActive);
    }
    if(cartActive){
      setCartActive(false);
    }
  };

  return (
    <div className={menuActive ? styles.topBarActive : styles.topBar}>
      <div
        className={
          menuActive ? styles.logoContainerActive : styles.logoContainer
        }
      >
        <Logo />
      </div>
      <div
        className={
          menuActive ? styles.menuContainerActive : styles.menuContainer
        }
      >
        <RoutesBar onLinkClick={clickMenuHandler} />
      </div>

      <div
        className={
          menuActive ? styles.burgerContainerActive : styles.burgerContainer
        }
        onClick={clickMenuHandler}
      >
        <MenuBurger />
      </div>

      <div
        className={
          menuActive ? styles.basketContainerActive : styles.basketContainer
        }
        onClick={handleCartClick}
      >
        <BasketIcon />
      </div>
      {cartActive && (
            <Basket onCloseClick={handleCartClick} cartActive={cartActive}/>
          )}
    </div>
  );
};

export default Topbar;
