import Map from "../MapComponent/Map";
import styles from "./Contact.module.css";
import image from "./contactImage.jpg";

const Contact = () => {
  return (
    <div className={styles.contactBox}>
      <div className={styles.content}>
        <h1>
          <i>Skontaktuj się z nami</i>
        </h1>
        <p>
          w sprawie zamówień:
          <ul>
            <li>
              <a className={styles.telLink} href="tel:914240602">
                tel./fax : 91 42 40 602
              </a>
            </li>
            <li>watex.fabrykapierogow@gmail.com</li>
          </ul>
          <br />W razie innych pytań prosimy dzwonić pod ten numer:{" "}
          <a className={styles.telLink} href="tel:6017802785">tel.kom.: 601 780 285</a> <br />
          <br />
          Zamówienia złożone do godziny 18 zostaną bezpłatnie dostarczone w
          najbliższy dzień roboczy w godzinach 9 - 13. <br /> <br />
          Możliwy również odbiór własny zamówienia w wyżej wymienionych
          godzinach.
        </p>
      </div>

      <div className={styles.col2}>
        <div className={styles.map}>
          <Map />
        </div>
        <div className={styles.info}>
          <p>
            ul. Widuchowska 17 71-717 Szczecin<br></br>P.P.U.H. Watex S.C. – J.
            Wacławski, Z. Wacławska
            <br></br>
            Regon 812539503
            <br></br>
            Nip 8512800736
            <br></br>
            Nr konta 62 1500 1722 1217 2005 0206 0000
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
