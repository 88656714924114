import { Link, useLocation } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { useState } from "react";
import styles from "./RoutesBar.module.css";

const RoutesBar = (props) => {
  const [selectedPage, setSelectedPage] = useState(useLocation().pathname);

  const setPageHome = () => {
    setSelectedPage("/");
    props.onLinkClick();
  };
  const setPageAbout = () => {
    setSelectedPage("/about");
    props.onLinkClick();
  };

  const setPageContact = () => {
    setSelectedPage("/contact");
    props.onLinkClick();
  };
  const setPageProducts = () => {
    setSelectedPage("/products");
    props.onLinkClick();
  };
  const setPageCeramics = () => {
    setSelectedPage("/ceramics");
    props.onLinkClick();
  };

  const linkStyle = {
    color: "rgb(123, 73, 25)",
  };
  const selectedLinkStyle = {
    textDecoration: "solid underline 3px",
    textUnderlineOffset: "8px",
    color: "rgb(123, 73, 25)",
    textAlign: "top",
    marginBottom: "8px",
  };

  return (
    <ul className={styles.itemsContainer}>
      <li>
        <Link to="/">
          <MenuItem onClick={setPageHome}>
            {" "}
            <h2 style={selectedPage === "/" ? selectedLinkStyle : linkStyle}>
              Strona główna
            </h2>{" "}
          </MenuItem>
        </Link>
      </li>
      <li>
        <Link to="/o_firmie">
          <MenuItem onClick={setPageAbout}>
            {" "}
            <h2
              style={selectedPage === "/o_firmie" ? selectedLinkStyle : linkStyle}
            >
              O firmie
            </h2>{" "}
          </MenuItem>
        </Link>
      </li>
      <li>
        <Link to="/produkty">
          <MenuItem onClick={setPageProducts}>
            <h2
              style={
                selectedPage === "/produkty" ? selectedLinkStyle : linkStyle
              }
            >
              Produkty
            </h2>
          </MenuItem>
        </Link>
      </li>
      <li>
        <Link to="/kontakt">
          <MenuItem onClick={setPageContact}>
            <h2
              style={
                selectedPage === "/kontakt" ? selectedLinkStyle : linkStyle
              }
            >
              Kontakt
            </h2>
          </MenuItem>
        </Link>
      </li>
      {/* <li>
        <Link to="/ceramics">
          <MenuItem onClick={setPageCeramics}>
            <h2
              style={
                selectedPage === "/ceramics" ? selectedLinkStyle : linkStyle
              }
            >
              Ceramika
            </h2>
          </MenuItem>
        </Link>
      </li> */}
    </ul>
  );
};

export default RoutesBar;
