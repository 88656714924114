import { BsInstagram } from "react-icons/bs";
const IgLink = ({size}) => {
  return (
    <div>
      <a href="https://www.instagram.com/watexfabrykapierogow" target="_blank">
        <BsInstagram size={size} />
      </a>
    </div>
  );
};

export default IgLink;
