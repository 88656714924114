import styles from './CartButton.module.css'

const CartButton = (props) => {


    return (

        <button className={styles.cartButton}>
            {props.text}
        </button>

    );

}


export default CartButton;