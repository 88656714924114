import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Badge from "@material-ui/core/Badge";
import Basket from "./Basket";
import React from "react";

import { BasketContext } from "../../BasketContext";

import { useContext } from "react";


import styles from './Basket.module.css'
import { MenuItem } from "@mui/material";

const BasketIcon = (props) => {
  const {productsList} = useContext(BasketContext);

  const size = productsList.length;

  return (
    <MenuItem>
      <Badge
        color="primary"
        badgeContent={size}
        style={{ fontSize: 50 }}
        overlap="circular"
      >
        <ShoppingBasketIcon style={{ color: "rgb(123, 73, 25)", fontSize: 50 }} />{" "}
      </Badge>
    </MenuItem>
  );
};

export default BasketIcon;
