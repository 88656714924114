import styles from "./PlaceOrder.module.css";
import { BasketContext } from "../../BasketContext";
import { Button, MenuItem } from "@material-ui/core";
import { useContext, useRef, useState, useEffect } from "react";

import deliveryGif from "../../Photos/delivery.gif";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { FaTrashAlt } from "react-icons/fa";
import CartButton from "../../UI/Buttons/CartButton";

import TextField from "@material-ui/core/TextField";
import { Controller, useForm } from "react-hook-form";
import emailjs from "emailjs-com";

import { MdClose } from "react-icons/md";

import apiKey from "../../emailkey";

const OrderTableRow = ({
  product,
  onDeleteClick,
  onIncreaseProduct,
  onDecreaseProduct,
}) => {
  return (
    <TableRow>
      <TableCell align="center">{product.name}</TableCell>
      <TableCell align="center">
        <div>
          <Button onClick={onDecreaseProduct}>-</Button>
          {" " + product.amount + " "}
          <Button onClick={onIncreaseProduct}>+</Button>
        </div>
      </TableCell>
      <TableCell align="center">{product.price * 2 * product.amount}</TableCell>
      <TableCell>
        <div onClick={onDeleteClick} className={styles.bin}>
          <FaTrashAlt />
        </div>
      </TableCell>
    </TableRow>
  );
};

const OrderTable = () => {
  const { productsList, removeProduct, increaseAmount, decreaseAmount } =
    useContext(BasketContext);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">Produkt</TableCell>
          <TableCell align="center">Ilość (kg)</TableCell>
          <TableCell align="center">Cena</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productsList.map((product, i) => {
          return (
            <OrderTableRow
              product={product}
              key={i}
              onDeleteClick={() => removeProduct(i)}
              onIncreaseProduct={() => increaseAmount(i)}
              onDecreaseProduct={() => decreaseAmount(i)}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const ConfirmationPanel = () => {
  return (
    <div className={styles.confirmationPanel}>
      <div className={styles.confirmationText}>
        <h1>Zamówienie zostało złożone!</h1>
        <h2>Wysłano potwierdzenie na podany adres e-mail</h2>
        <h2>
          Płatność gotówką przy odbiorze, lub przelewem po otrzymaniu
          zamówienia.
        </h2>
        <img src={deliveryGif}></img>
      </div>
    </div>
  );
};

const OrderForm = ({ orderPrice, onOrderConfirm }) => {
  const form = useRef();
  const { handleSubmit, reset, control } = useForm();

  const { productsList, clearBasket } = useContext(BasketContext);

  const [emailValidated, setEmailValidated] = useState(true);

  const [phoneValidated, setPhoneValidated] = useState(true);

  const handleOrder = (data) => {
    console.log(data.phone.length);

    const phoneValid = data.phone.length >= 9 && !/[a-z]/i.test(data.phone);
    console.log(phoneValid);

    const mailValid = data.mail.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (mailValid && phoneValid) {
      setEmailValidated(true);
      setPhoneValidated(true);
      onOrderConfirm();
      let orderString = "";

      productsList.forEach((p) => {
        orderString += p.name + " " + p.amount + " kg<br></br>";
      });

      const orderParams = {
        name: data.name,
        surname: data.surname,
        address: data.address,
        email: data.mail,
        phone: data.phone,
        order: orderString,
      };
      emailjs
        .send("watexTest", apiKey.TEMPLATE_ID, orderParams, apiKey.USER_ID)
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    } else if (!phoneValid && mailValid) {
      setEmailValidated(true);
      setPhoneValidated(false);
    } else if (!mailValid && phoneValid) {
      setPhoneValidated(true);
      setEmailValidated(false);
    } else if (!mailValid && !phoneValid) {
      setPhoneValidated(false);
      setEmailValidated(false);
    }
  };

  return (
    <div>
      <form
        ref={form}
        styles={{ display: "flex" }}
        onSubmit={handleSubmit(handleOrder)}
      >
        <Controller
          name={"name"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              required={true}
              fullWidth
              onChange={onChange}
              value={value}
              label={"Imię"}
              lenght={150}
            />
          )}
        />
        <br></br>
        <br></br>
        <Controller
          name={"surname"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              required={true}
              fullWidth
              onChange={onChange}
              value={value}
              label={"Nazwisko"}
            />
          )}
        />
        <br></br>
        <br></br>
        <Controller
          name={"mail"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              error={emailValidated ? null : true}
              helperText={emailValidated ? "" : "Niepoprawny adres e-mail"}
              required={true}
              fullWidth
              onChange={onChange}
              value={value}
              label={"E-mail"}
              type="email"
            />
          )}
        />

        <br></br>
        <br></br>

        <Controller
          name={"address"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              required={true}
              fullWidth
              onChange={onChange}
              value={value}
              label={"Adres"}
            />
          )}
        />
        <br></br>
        <br></br>
        <Controller
          name={"phone"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              required={true}
              fullWidth
              onChange={onChange}
              value={value}
              label={"Numer telefonu"}
              lenght={150}
              type="tel"
              error={phoneValidated ? null : true}
              helperText={phoneValidated ? "" : "Niepoprawny numer telefonu"}
            />
          )}
        />
        <br></br>
        <div className={styles.orderButton}>
          <Controller
            name={"mail"}
            control={control}
            render={() => <CartButton text="Zamów"></CartButton>}
          />
        </div>
      </form>
    </div>
  );
};

const PlaceOrder = ({ onClickOutside }) => {
  const { productsList, clearBasket } = useContext(BasketContext);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleShowConfirmation = () => {
    setShowConfirmation(true);
    setTimeout(function () {
      onClickOutside();
      setShowConfirmation(false);
      clearBasket();
    }, 3000);
  };

  const totalPrice = productsList
    .map((p) => p.price * 2 * p.amount)
    .reduce((acc, curr) => acc + curr, 0);

  if (showConfirmation) {
    return (
      <div onClick={onClickOutside}>
        <ConfirmationPanel
          onClick={() => {
            clearBasket();
            onClickOutside();
            setShowConfirmation(false);
          }}
        />
      </div>
    );
  }
  if (productsList.length > 0) {
    return (
      <>
        <div onClick={onClickOutside} className={styles.overlay} />
        <div className={styles.orderDiv}>
          <MenuItem onClick={() => onClickOutside()}>
            <MdClose size={32} />
            Zamknij
          </MenuItem>
          <h1 className={styles.orderHeader}>Składanie zamówienia</h1>
          <div className={styles.content}>
            <div className={styles.clientForm}>
              <OrderForm
                orderPrice={totalPrice}
                onOrderConfirm={handleShowConfirmation}
              ></OrderForm>
            </div>
            <div className={styles.order}>
              <div className={styles.orderBox}>
                <TableContainer
                  sx={{
                    maxHeight: "100%",
                  }}
                >
                  <OrderTable />
                </TableContainer>
              </div>
              <div className={styles.totalPrice}>
                <h1>Cena zamówienia: {totalPrice} zł</h1>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.overlay} onClick={onClickOutside} />
        <div className={styles.emptyBasket}>Brak produktów w koszyku!</div>
      </>
    );
  }
};

export default PlaceOrder;
