import { MenuItem } from "@mui/material";

import { MdMenu } from "react-icons/md";
const MenuBurger = () => {
  return (
    <MenuItem style={{ width: "100%", }}>
      <MdMenu style={{ color: "rgb(123, 73, 25)", fontSize: 50 }} />
    </MenuItem>
  );
};

export default MenuBurger;
