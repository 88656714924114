import FacebbokLink from "../../UI/SocialMedia/FacebookLink";
import IgLink from "../../UI/SocialMedia/InstagramLink";
import styles from "./AboutPage.module.css";
import image from "./dumplings.jpg";
import leaf from "./leaf.png";

const AboutPage = () => {
  return (
      <div className={styles.aboutGrid}>
        <div className={styles.imageDiv}>
          <img src={image} className={styles.image}></img>
          <h2>Nasze media społecznościowe:</h2>

          <div className={styles.fb}>
            {" "}
            <FacebbokLink size={70}></FacebbokLink>{" "}
          </div>
          <div className={styles.ig}>
            {" "}
            <IgLink size={70}></IgLink>{" "}
          </div>
        </div>
        <div className={styles.about}>
          <h1>O firmie</h1>
          <p className={styles.par}>
            Watex Fabryka Pierogów to lokalna, szczecińska firma działająca od
            1990 roku. <br></br>
            <br></br>
            Od początku jej powstania dokładamy największych starań, by
            wszystkie nasze produkty były w 100% naturalne i były częścią
            zdrowej, zbilansowanej diety.
            <br></br>
            <br></br>
            Staramy się uwzględnić wszystkich naszych klientów przy planowaniu
            naszego asortymentu, więc produkujemy również produkty z mąki
            bezglutenowej oraz wegetariańskie.
          </p>
        </div>
      </div>
  );
};

export default AboutPage;
