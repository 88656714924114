import styles from "./HomePage.module.css";
import mainImage from "./mainimage.jpg";
import howImg from "./how.png";
import FacebbokLink from "../../UI/SocialMedia/FacebookLink";
import InstagramLink from "../../UI/SocialMedia/InstagramLink";
import IgLink from "../../UI/SocialMedia/InstagramLink";
import roll from "./roll.png";
import Bottombar from "../../UI/BottomBar/Bottombar";

import pierogi1 from "../../Photos/pierogi1.jpg";
import { Instagram } from "@material-ui/icons";
import Newsletter from "../Newsletter/Newsletter";
import { BsFillTelephoneFill } from "react-icons/bs";


const Homepage = () => {
  return (
    <div>
      <div>
        <div className={styles.mainPicture}>
          <img src={pierogi1}></img>
        </div>
        <div className={styles.onPic}>
          <h1>
            <i>Z dbałością o każdy szczegół</i>
          </h1>
          <h1>
            <i>od 1990 roku...</i>
          </h1>
        </div>
        <div className={styles.mainText}>
          <div className={styles.tel}>
            <a className={styles.telLink} href="tel:914240602"><BsFillTelephoneFill/><h2>tel. 91 424 06 02</h2></a>
          </div>
          <h1>
            Jesteśmy małą, rodzinną firmą działającą w Szczecinie od 1990 roku.
            <br></br>
            Nasze produkty garmażeryjne robione są ręcznie bez użycia konserwantów.<br></br>
            Zaopatrujemy sklepy, stołówki, bary, cateringi oraz <br></br>
            klientów indywidualnych.<br></br>
          </h1>
          <div>
            <div className={styles.social}>
              <FacebbokLink size={50} />
            </div>
            <div className={styles.social}>
              <InstagramLink size={50} />
            </div>
          </div>
        </div>
        <div className={styles.Newsletter}>
          <Newsletter />
        </div>
      </div>
      <div className={styles.buffor}></div>
      {
        /*
      
      <div className={styles.reviews}>
        <div className={styles.review}>
          <iframe
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fwojciech.czacharowski%2Fposts%2F10220230410539256&show_text=true&width=100"
            scrolling="yes"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
        <div className={styles.review}>
          <iframe
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fadrianna.luntkowska.7%2Fposts%2F3866149836752593&show_text=true&width=500"
            scrolling="yes"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
        <div className={styles.review}>
          <iframe
            src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D10226543879008008%26id%3D1376881321&show_text=true&width=500"
            scrolling="yes"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
      */
      }
    </div>
  );
};

export default Homepage;

{
  /*
<div className={styles.mainDiv}>
<div className={styles.homeTopDiv}>
  <div className={styles.mainImage}>
    <img src={mainImage}></img>
  </div>
  <div className={styles.tel}>
    <h2>tel. 91 424 06 02</h2>
  </div>
  <div className={styles.mainText}>
    <h1>Z dbałością o każdy szczegół...</h1>
    <h2>
      Jesteśmy małą, rodzinna firmą działającą w Szczecinie od 1990 roku.<br></br>
      Nasze produkty robione są ręcznie bez użycia konserwantów.<br></br>
      Zaopatrujemy sklepy, stołówki, bary, cateringi oraz <br></br>
      klientów indywidualnych.<br></br>
    </h2>
    <div className={styles.fb}>
      {" "}
      <FacebbokLink></FacebbokLink>{" "}
    </div>
    <div className={styles.ig}>
      {" "}
      <IgLink></IgLink>{" "}
    </div>
  </div>
  <div className={styles.newThings}>
    <h1>Nowości</h1>
    <h2>
      Watex systematycznie poszerza swoją ofertę.<br></br>
      Sprawdź co nowego pojawiło się w Fabryce Pierogów.
    </h2>
    <div>tutaj zrobić rząd obrazków ładnie</div>
  </div>
  
  <div className={styles.how}>
    <div className={styles.howText}>
      <h1>Jak powstają nasze produkty?</h1>
      <br></br>
      <h2>
        W naszej Fabryce praca rozpoczyna się już <br></br>w nocy, kiedy
        większość mieszkańców Szczecina jeszcze śpi.<br></br>
        <br></br>
        Najpierw pojawiają się dostawcy. Pan Jacek przywozi warzywa ze
        swojego gospodarstwa spod Szczecina.<br></br>
        Dostarcza dorodne zimniaki, kapustę, cebulę. Potem przyjeżdża mąka
        z młyna z Zachodniopomorskiego, a następnie twaróg z najbliższej
        mleczarni.
      </h2>
    </div>
  </div>
  <div className={styles.howImg}>
    <img src={howImg}></img>
  </div>
</div>
<div className={styles.lastDiv}>
  <div className={styles.rollImg}>
    <img src={roll}></img>
  </div>
  <div className={styles.rollDiv}>
    <h2>
      Kucharze na swoich stanowiskach przygotowują farsze i ciasto.{" "}
      <br></br>
      Następnie ciasto jest wałkowane i rozpoczyna się lepienie: <br></br>
      pierogów ruskich, z mięsem, ze szpinakiem, z ziemniakami i boczkiem,
      truskawkami.<br></br>
      <br></br>
      Kiedy zaczyna świtać wszystko jest już gotowe. <br></br>
      Trzeba tylko te pyszności popakować i w drogę!
    </h2>
  </div>
</div>
<Bottombar></Bottombar>
</div>
*/
}
