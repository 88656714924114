import MailchimpSubscribe from "react-mailchimp-subscribe";

import styles from "./Newsletter.module.css";

import CartButton from "../../UI/Buttons/CartButton";
import { Button } from "@material-ui/core";
const Newsletter = () => {
  const url =
    "https://gmail.us14.list-manage.com/subscribe/post?u=975ffe9168183890735b49153&id=678a1d6227";
  return (
    <div>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <NewsletterForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

const NewsletterForm = ({ status, message, onValidated }) => {
  let email = "";

  const validateEmail = () => {
    return (email &&
    email.value.indexOf("@") > -1)
  }
  const submit = () =>{

    const valid = validateEmail();

    if(valid){
      onValidated({
        EMAIL: email.value,
      });
    }


  }

  return (
    <div className={styles.Newsletter}>
      <h2>Zapisz się do naszego newslettera!</h2>
      {status === "sending" && (
        <div style={{ color: "blue", marginLeft: "1rem"}}>
          Wpisywanie do listy subskrybentów...
        </div>
      )}
      {status === "error" && (
        <div
          style={{ color: "red", marginLeft: "1rem" }}
          dangerouslySetInnerHTML={{ __html: "Nie można dopisać do adresu subskrybentów!" }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green", marginLeft: "1rem" }}
          dangerouslySetInnerHTML={{ __html: "Dziękujemy za subskrypcję!" }}
        />
      )}
      <br />
      <input
        style={{ fontSize: "2em", padding: 5, width: "90%",   fontFamily: "Playfair Display, serif" }}
        ref={(node) => (email = node)}
        type="email"
        placeholder="Twój email"
      />
      <br />
      <Button
        onClick={submit}
        variant="contained"
        color='primary'
        style={{
          margin:'1rem',
          fontFamily: 'Playfair Display, serif',
        }}
        size='large'
      >
          Zapisz się!
      </Button>
    </div>
  );
};

export default Newsletter;
