import { Button, MenuItem } from "@material-ui/core";
import CartButton from "../../UI/Buttons/CartButton";
import styles from "./Basket.module.css";
import Popover from "@mui/material/Popover";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@material-ui/core/styles";

import { useState, useContext } from "react";

import Backdrop from "@material-ui/core/Backdrop";

import PlaceOrder from "../PlaceOrder/PlaceOrder";

import { BasketContext } from "../../BasketContext";
import { FaTrashAlt } from "react-icons/fa";

import { AiOutlineArrowLeft } from "react-icons/ai";

export const BasketRow = ({
  product,
  onDeleteClick,
  onIncreaseProduct,
  onDecreaseProduct,
}) => {
  return (
    <TableRow>
      <TableCell align="left">{product.name}</TableCell>
      <TableCell align="left">
        <div>
          <Button onClick={onDecreaseProduct}>-</Button>
          {" " + product.amount + " "}
          <Button onClick={onIncreaseProduct}>+</Button>
        </div>
      </TableCell>
      <TableCell align="left">
        {product.price * 2 * product.amount} zł
      </TableCell>
      <TableCell>
        <div onClick={onDeleteClick} className={styles.bin}>
          <FaTrashAlt />
        </div>
      </TableCell>
    </TableRow>
  );
};

export const BasketTable = () => {
  const { productsList, removeProduct, increaseAmount, decreaseAmount } =
    useContext(BasketContext);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Produkt</TableCell>
          <TableCell align="left">Ilość (kg)</TableCell>
          <TableCell align="left">Cena</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productsList.map((product, i) => {
          return (
            <BasketRow
              product={product}
              key={i}
              onDeleteClick={() => removeProduct(i)}
              onIncreaseProduct={() => increaseAmount(i)}
              onDecreaseProduct={() => decreaseAmount(i)}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    height: "100vh",
  },
}));

const Basket = (props) => {
  const { productsList } = useContext(BasketContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const totalPrice = productsList
    .map((p) => p.price * 2 * p.amount)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <div className={props.cartActive ? styles["basketBox"] : ""}>
      <div>
        <div className={styles.cross} onClick={props.onCloseClick}>
          <MenuItem>
            <AiOutlineArrowLeft size={30} />
          </MenuItem>
        </div>
        <div className={styles.yourOrder}>
          <h2>Twoje zamówienie</h2>
        </div>
      </div>
      <div className={styles.order}>
        <TableContainer
          sx={{
            maxHeight: "93%",
          }}
        >
          <BasketTable></BasketTable>
        </TableContainer>
        <div className={styles.totalPrice}>
          <h2>Wartość zamówienia: {totalPrice} zł</h2>
        </div>
      </div>
      <div className={styles.button} onClick={() => setOpen(true)}>
        <CartButton text={"Zamów teraz!"} />
      </div>
      <Backdrop open={open} className={classes.backdrop}>
        <PlaceOrder onClickOutside={() => setOpen(false)}></PlaceOrder>
      </Backdrop>
    </div>
  );
};

export default Basket;
