
import { BsFacebook } from "react-icons/bs";

const FacebbokLink = ({size}) => {
  return (
    <div>
      <a
        href="https://www.facebook.com/profile.php?id=100045131599126"
        target="_blank"
      >
        <BsFacebook size={size} ></BsFacebook>
      </a>
    </div>
  );
};

export default FacebbokLink;
