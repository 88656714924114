import "./App.css";
import React from "react";
import AboutPage from "./Components/AboutPage/AboutPage";
import Topbar from "./UI/TopBar/TopBar";
import {
  BrowserRouter,
  Route,
} from "react-router-dom/cjs/react-router-dom.min";
import { Switch } from "react-router-dom";
import Homepage from "./Components/HomePage/Homepage";
import Contact from "./Components/Contact/Contact";
import Products from "./Components/Products/Products";
import { useEffect, useState } from "react";
import { BasketContext } from "./BasketContext";
import Ceramics from "./Components/Ceramics/Ceramics";
import Basket from "./Components/Basket/Basket";

import ScrollToTop from "./UI/ScrollToTop";
import apiRouting from "./apiRouting";


function compareProduct(a, b){
  const available1 = (a[5].toLowerCase?.() === "true")
  const available2 = (b[5].toLowerCase?.() === "true")
  
  return Number(available2) - Number(available1)
}

function App() {
  const [products, setProducts] = useState();

  useEffect(() => {
    fetch(apiRouting.apiAddess)
      .then((response) => response.json())
      .then((data) => {
        var normal = data.list.normal;
        var glutenFree = data.list.glutenFree;

        normal.shift();

        glutenFree.shift();

        normal = normal.sort((a,b) => compareProduct(a,b))
        glutenFree = glutenFree.sort((a,b) => compareProduct(a,b))

        const parsedLists = {
          normal,
          glutenFree,
          filteredNormal: normal,
          filteredGlutenFree: glutenFree,
        };

        setProducts(parsedLists);
      });
  }, []);

  const [productsList, setProductsList] = useState([]);

  // const addProduct = (item, amount) => {
  //   let currentProduct = productsList.find((p) => p.name === item[0]);

  //   if (currentProduct == null) {
  //     setProductsList([
  //       ...productsList,
  //       { price: item[1], name: item[0], unit: item[3], amount },
  //     ]);
  //     return;
  //   }

  //   currentProduct.amount = currentProduct.amount + amount;

  //   const newProductsList = productsList.map(p => p.name !== item[0] ? p : currentProduct);

  //   setProductsList(newProductsList)
  // };

  const addProduct = (item, amount) => {
    console.log(item);
    setProductsList([
      ...productsList,
      { price: item[1], name: item[0], unit: item[3], amount },
    ]);
  };

  const increaseAmount = (index) => {
    let copyProductsList = [...productsList];
    copyProductsList[index].amount += 0.5;

    setProductsList(copyProductsList);
  };

  const decreaseAmount = (index) => {
    if (productsList[index].amount > 0.5) {
      let copyProductList = [...productsList];
      copyProductList[index].amount -= 0.5;

      setProductsList(copyProductList);
    } else {
      removeProduct(index);
    }
  };

  const removeProduct = (index) => {
    setProductsList(productsList.filter((value, i) => i !== index));
  };

  const clearBasket = () => {
    setProductsList([]);
  };

  return (
    <div className="App">
      <BasketContext.Provider
        value={{
          productsList,
          addProduct,
          removeProduct,
          increaseAmount,
          decreaseAmount,
          clearBasket,
        }}
      >
        <BrowserRouter>
          <Topbar />
          {/* {cartActive && (
            <Basket onCloseClick={handleCartClick} cartActive={cartActive} />
          )} */}
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Homepage></Homepage>
            </Route>
            <Route path="/o_firmie">
              <AboutPage />
            </Route>
            <Route path="/kontakt">
              <Contact></Contact>
            </Route>
            <Route path="/produkty">
              <Products products={products} />
            </Route>
            {/* <Route path="/ceramics">
              <Ceramics />
            </Route> */}
          </Switch>
        </BrowserRouter>
      </BasketContext.Provider>
    </div>
  );
}

export default App;
